<template>
	<form class="c-discount-code" :class="{'c-discount-code--applied': isCouponApplied}">
		<div class="c-discount-code__input-group">
			<label for="discountCodeId" class="c-discount-code__label">{{ labelHeader }}</label>
			<input
				v-model="couponName"
				id="discountCodeId"
				type="text"
				:placeholder="labels.couponPlaceholderText"
				class="c-discount-code__input"
				:disabled="isCouponApplied"
				autocomplete="off"
				@input="couponName = $event.target.value.toUpperCase()"
				data-testid="discount-code-input"
			/>
			<DsText html-element="div" text-type="body-sm"
				class="c-discount-code__error-validation"
				:class="{'show-error': hasError}">
				{{ labels.couponValidationErrorMessage }}
			</DsText>
		</div>
		<div class="c-discount-code__actions">
			<DsIconButton
				v-if="isCouponApplied"
				variant="ghost-neutral"
				icon-name="delete"
				:ariaLabel="labels.accessibility.removeCouponCode"
				@click.prevent="removeCouponCode"
				data-testid="discount-code-remove"
			/>
			<DsIconButton
				v-else
				variant="primary"
				icon-name="navigate-forward"
				:ariaLabel="labels.accessibility.applyCouponCode"
				@click.prevent="applyCouponCode"
				:disabled="couponName === ''"
				data-testid="discount-code-check-discount"
			/>
		</div>
	</form>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { DsText, DsIconButton } from "coloplast-design-system";

export default {
	name: "discount-code",
	props: {
		labels: {
			type: Object,
			default: () => {}
		},
		isCouponApplied: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			loading: false,
			error: null,
			couponName: '',
			hasError: false,
		};
	},
	components: {
		DsText,
		DsIconButton
	},
	computed: {
		...mapGetters({
			culture: "getCulture",
			basket: 'getBasket',
		}),
		labelHeader() {
			return  (this.isCouponApplied) ? this.labels.couponAppliedHeaderLabel : this.labels.couponHeaderLabel
		},

	},
	watch: {
		couponName: {
			handler: function(value) {
				this.setCoupon(value)
			},
			immediate: true
		}
	},
	methods: {
		setCoupon(value){
			if(this.isCouponApplied) {
				this.couponName = this.basket?.appliedCouponCode;
			}
			this.hasError = false;
			return value;
		},
		...mapActions([
			'applyCouponBasket',
			'removeCouponBasket'
		]),
		async applyCouponCode() {
			const payload = {
				language: this.culture,
				CouponCode: this.couponName,
			};

			try {
				const response = await this.applyCouponBasket(payload);
				if(!response.data && response.status !== 200 ) {
					throw new Error();
				}
				this.setCoupon(this.couponName);
			}
			catch {
				this.hasError = true;
			}

		},
		async removeCouponCode() {
			const payload = {
				language: this.culture,
				CouponCode: this.couponName,
			};
			await this.removeCouponBasket(payload);
			this.couponName = '';
		},
	},
	mounted() {
		this.setCoupon(this.couponName)
	}
}
</script>
