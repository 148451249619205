<template>
	<div
		class="c-global-basket__empty-basket-info-container">
		<figure class="c-global-basket__empty-basket-icon">
			<DsIcon icon-name="shopping-basket" size="xl" />
		</figure>
		<DsText
			html-element="span"
			text-type="body-lg"
			class="c-global-basket__empty-basket-message">
			{{ labels.emptyBasketText }}
		</DsText>
		<DsButton
			variant="primary"
			:href="emptyBasketUrlHref"
			:title="labels.emptyBasketLinkText"
			size="lg">
			{{ labels.emptyBasketLinkText }}
		</DsButton>
	</div>
</template>

<script>
import { DsButton, DsIcon, DsText } from 'coloplast-design-system';

	export default {
		name: 'basket-empty-state',
		props: {
			labels: {
				type: Object,
				required: true,
			},
			emptyBasketUrl: {
				type: String,
				default: null,
			},
		},
		components: {
			DsIcon,
			DsButton,
			DsText,
		},
		computed: {
			emptyBasketUrlHref() {
				return this.emptyBasketUrl ? this.emptyBasketUrl : '/';
			},
		},
	};
</script>
